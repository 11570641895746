<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-light shadow border-bottom bg-white py-1">
    <div class="d-flex align-items-center justify-content-center p-0 w-100">
      <div class="d-flex navigator-flex-column w-100 px-0" style="max-width: 1300px;">
        <a class="navbar-brand" href="#">
          <img src="@/assets/logo.webp" alt="Logo sesion" style="height: 30px;">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav nav-pills mr-auto pb-1">

          </ul>
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="nav-link active px-3" href="#inicio"> Inicio </a>
            </li>
            <li class="nav-item">
              <a class="nav-link px-3" href="#nosotros"> Nosotros </a>
            </li>
            <li class="nav-item   ">
              <a class="nav-link rounded-pill px-3" href="#servicios"> Servicios </a>
            </li>
            <li class="nav-item  ">
              <a class="nav-link rounded-pill px-3" href="#contacto"> Contacto </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <div class="container-fluid w-100" style="max-width: 1300px;">

    <div id="carouselExampleControls" class="carousel slide " data-ride="carousel">
      <div id="inicio" class="carousel-inner rounded" style="height: 500px; margin-top: 45px;">

        <div class="carousel-item active bg-secondary w-100 h-100  shadow" style="background: center;background-size: cover;"  :style="{ backgroundImage: 'url(' + require('@/assets/01242023/img_slide_6.webp') + ')' }">

          <div class="rounded p-3 shadow card-slide-width text-center" style="position: absolute; bottom: 1.3em; right: 1.3em; background-color: #e0f7fa87">
            <img src="@/assets/logo.webp" >
          </div>

        </div>
        <div class="carousel-item bg-secondary w-100 h-100  shadow" style="background: center;background-size: cover;"  :style="{ backgroundImage: 'url(' + require('@/assets/01242023/img_slide_7.webp') + ')' }">

          <div class="rounded p-3 shadow card-slide-width text-center" style="position: absolute; bottom: 1.3em; right: 1.3em; background-color: #e0f7fa87">
            <img src="@/assets/logo.webp" >
          </div>

        </div>
        <div class="carousel-item bg-secondary w-100 h-100  shadow" style="background: center;background-size: cover;"  :style="{ backgroundImage: 'url(' + require('@/assets/01242023/img_slide_8.webp') + ')' }">

          <div class="rounded p-3 shadow card-slide-width text-center" style="position: absolute; bottom: 1.3em; right: 1.3em; background-color: #e0f7fa87">
            <img src="@/assets/logo.webp" >
          </div>

        </div>
        <div class="carousel-item bg-secondary w-100 h-100  shadow" style="background: center;background-size: cover;"  :style="{ backgroundImage: 'url(' + require('@/assets/01242023/img_slide_9.webp') + ')' }">

          <div class="rounded p-3 shadow card-slide-width text-center" style="position: absolute; bottom: 1.3em; right: 1.3em; background-color: #e0f7fa87">
            <img src="@/assets/logo.webp" >
          </div>

        </div>
        <div class="carousel-item bg-secondary w-100 h-100  shadow" style="background: center;background-size: cover;"  :style="{ backgroundImage: 'url(' + require('@/assets/01242023/img_slide_10.webp') + ')' }">

          <div class="rounded p-3 shadow card-slide-width text-center" style="position: absolute; bottom: 1.3em; right: 1.3em; background-color: #e0f7fa87">
            <img src="@/assets/logo.webp" >
          </div>

        </div>
        <div class="carousel-item bg-secondary w-100 h-100  shadow" style="background: center;background-size: cover;"  :style="{ backgroundImage: 'url(' + require('@/assets/01242023/img_slide_11.webp') + ')' }">

          <div class="rounded p-3 shadow card-slide-width text-center" style="position: absolute; bottom: 1.3em; right: 1.3em; background-color: #e0f7fa87">
            <img src="@/assets/logo.webp" >
          </div>

        </div>
        <div class="carousel-item bg-secondary w-100 h-100  shadow" style="background: center;background-size: cover;"  :style="{ backgroundImage: 'url(' + require('@/assets/img_slide_1.webp') + ')' }">

          <div class="rounded p-3 shadow card-slide-width text-center" style="position: absolute; bottom: 1.3em; right: 1.3em; background-color: #e0f7fa87">
            <img src="@/assets/logo.webp" >
          </div>

        </div>
        <div class="carousel-item  bg-secondary w-100 h-100  shadow" style="background: center;background-size: cover;" :style="{ backgroundImage: 'url(' + require('@/assets/img_slide_2.webp') + ')' }">
          <div class="rounded p-3 shadow card-slide-width text-center" style="position: absolute; bottom: 1.3em; right: 1.3em; background-color: #e0f7fa87">
            <img src="@/assets/logo.webp" >
          </div>
        </div>
        <div class="carousel-item  bg-secondary w-100 h-100  shadow" style="background: center;background-size: cover;" :style="{ backgroundImage: 'url(' + require('@/assets/img_slide_3.webp') + ')' }">
          <div class="rounded p-3 shadow card-slide-width text-center" style="position: absolute; bottom: 1.3em; right: 1.3em; background-color: #e0f7fa87">
            <img src="@/assets/logo.webp">
          </div>
        </div>
        <div class="carousel-item  bg-secondary w-100 h-100  shadow" style="background: center;background-size: cover;" :style="{ backgroundImage: 'url(' + require('@/assets/img_slide_4.webp') + ')' }">
          <div class="rounded p-3 shadow card-slide-width text-center" style="position: absolute; bottom: 1.3em; right: 1.3em; background-color: #e0f7fa87">
            <img src="@/assets/logo.webp" >
          </div>
        </div>
        <div class="carousel-item  bg-secondary w-100 h-100  shadow" style="background: center;background-size: cover;" :style="{ backgroundImage: 'url(' + require('@/assets/img_slide_5.webp') + ')' }">
          <div class="rounded p-3 shadow card-slide-width text-center" style="position: absolute; bottom: 1.3em; right: 1.3em; background-color: #e0f7fa87">
            <img src="@/assets/logo.webp" >
          </div>
        </div>
      </div>

      <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>

      <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <div id="nosotros" class="container-fluid px-0 pt-2">
      <div class="row no-gutters">

        <div class="col-12 col-lg-4 py-2 px-0 pr-lg-1 d-flex">
          <div class="card shadow">
            <img src="@/assets/img_quienes_somos.webp" class="card-img-top zoom" alt="..." style="height: 17em;">
            <div class="card-body font-size-card-img">
              <h5 class="card-title font-weight-bold text-center">Quienes somos</h5>
              <p class="card-text" style="text-align: justify"> Somos una empresa 100% mexicana, dedicada al autotransporte de carga nacional e internacional.</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-4 py-2 px-0 px-lg-1 d-flex">
          <div class="card shadow">
            <img src="@/assets/truck2.webp" class="card-img-top zoom" alt="..." style="height: 17em;">
            <div class="card-body font-size-card-img">
              <h5 class="card-title font-weight-bold text-center">Misi&oacute;n</h5>
              <p class="card-text" style="text-align: justify">Ser la soluci&oacute;n perfecta de autotransporte para nuestros clientes, brindando siempre un servicio de excelente calidad en tiempo y forma, superando las expectativas.</p>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-4 py-2 px-0 pl-lg-1 d-flex">
          <div class="card shadow">
            <img src="@/assets/img_vision.webp" class="card-img-top zoom" alt="..." style="height: 17em;">
            <div class="card-body font-size-card-img">
              <h5 class="card-title font-weight-bold text-center">Visi&oacute;n</h5>
              <p class="card-text" style="text-align: justify">Ser una empresa l&iacute;der en el mercado, siendo altamente competitivos y confiables, acrecentando el recurso humano y financiero.</p>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="container-fluid px-0 pt-2">

      <div class="col-12 p-0  bg-white shadow border">
        <div class="d-flex align-items-center justify-content-center p-0" style="height: 50vw; max-height: 580px; background: center;background-size: cover;" :style="{ backgroundImage: 'url(' + require('@/assets/img_historia.webp') + ')' }">
          <div class="flex-column rounded app-center-text-div p-4 font-size-card-img" style="height: 50%; width: 85%; background-color: #fafafaa8;">
            <h5 class="font-weight-bold" >Historia</h5>
            <p class="text-justify">Nuestra empresa inicio operaciones en el 2002, nuestro fundador y Director General el Sr. Gustavo Gonzalez Hernández comenz&oacute; las rutas de transporte al interior de la republica con tan solo una unidad,  hoy en d&iacute;a nos hemos consolidado con unidades que superan las expectativas de nuestros clientes</p>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 bg-white shadow border">
        <div class="d-flex align-items-center justify-content-center p-0" style="height: 50vw; max-height: 580px;  background: center;background-size: cover;" :style="{ backgroundImage: 'url(' + require('@/assets/tableroGps.webp') + ')' }">
          <div class="flex-column rounded app-center-text-div p-4 font-size-card-img" style="height: 50%; width: 85%; background-color: #fafafaa8;">
            <h4 class="font-weight-bold">Tecnolog&iacute;a</h4>
            <p class="text-justify">Con la finalidad de acrecentar la comunicaci&oacute;n efectiva todas nuestras unidades de transporte cuentan con sistema de localizaci&oacute;n GPS. Adem&aacute;s de comunicaci&oacute;n celular con nuestras oficinas y tambi&eacute;n cuentan con radio frecuencia privada entre todas las unidades de transporte.</p>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 bg-white shadow border">
        <div class="d-flex align-items-center justify-content-center p-0" style="height: 50vw; max-height: 580px;  background: center;background-size: cover;" :style="{ backgroundImage: 'url(' + require('@/assets/img_carroceria.webp') + ')' }">
          <div class="flex-column rounded app-center-text-div p-4 font-size-card-img" style="height: 70%; width: 85%; background-color: #fafafaa8;">
            <h4 class="font-weight-bold">Taller mec&aacute;nico</h4>
            <p>Somos un grupo de t&eacute;cnicos certificados con mas de 15 años de experiencia en el ramo mec&aacute;nico, con convicciones, honestos y comprometidos a ofrecer un servicio calificado a nuestros clientes en general. Ofrecemos servicios preventivos y correctivos. Reparamos motores di&eacute;sel, nuestra especialidad:</p>
            <div>
              <ul style="list-style: none;">
                <li style="display: inline;">Volvo </li>
                <li style="display: inline;">| Cummins </li>
                <li style="display: inline;">| Caterpillar </li>
                <li style="display: inline;">| Detroit </li>
                <li style="display: inline;">| DD15</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 bg-white shadow border">
        <div class="d-flex align-items-center justify-content-center p-0" style="height: 50vw; max-height: 580px;  background: center;background-size: cover;" :style="{ backgroundImage: 'url(' + require('@/assets/img_mecanica.webp') + ')' }">
          <div class="flex-column rounded app-center-text-div p-4 font-size-card-img" style="height: 50%; width: 85%; background-color: #f5f3f3CC;">
            <h4 class="font-weight-bold">Taller carrocer&iacute;a y pintura</h4>
            <p>En Enchulame el troke nos especializamos en cambios radicales de la imagen de tu tractocamion, diseñamos y plasmamos tu sueño sobre ruedas. No solo es pintura, le ponemos alma y coraz&oacute;n (accesorios, cromos y luces)</p>
          </div>
        </div>
      </div>
      <div id="servicios" class="col-12 p-0 bg-white shadow border">
        <div class="d-flex align-items-center justify-content-center p-0" style="height: 50vw; max-height: 580px; background: center;background-size: cover;" :style="{ backgroundImage: 'url(' + require('@/assets/img_servicios.webp') + ')' }">
          <div class="flex-column rounded app-center-text-div p-4 font-size-card-img" style="height: 50%; width: 85%; background-color: #f5f3f3CC;">
            <h4 class="font-weight-bold">Servicios</h4>
            <div>

              <ul style="list-style: none">
                <li>Damos servicio de caja seca de 53 pies y refrigerado. </li>
                <li>Vamos a cualquier parte de la república mexicana. </li>
                <li>Efectuamos importaci&oacute;n y exportaci&oacute;n. </li>
                <li>Nuestra base esta en Ciudad Ju&aacute;rez, Chihuahua</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div id="contacto" class="container-fluid px-0 pt-3 mb-3">
      <div class="row no-gutters shadow h-100">
        <div class="col-12 col-lg-6 border">
          <div class="py-1 border-bottom">
            <h5 class="text-center font-weight-bold">Contacto</h5>
          </div>
          <div class="p-3 " style="height: 400px;">
            <div class="rounded border shadow-sm p-4 mb-3">
              <span><i class="bi bi-telephone"></i>&nbsp;&nbsp;&nbsp;<b>Tel&eacute;fono:</b>&nbsp;&nbsp;&nbsp;<a href="tel:+526563134632"> (656) 313 46 32 </a></span>
            </div>
            <div class="rounded border shadow-sm p-4 mb-3">
              <span><i class="bi bi-telephone"></i>&nbsp;&nbsp;&nbsp;<b>Tel&eacute;fono:</b>&nbsp;&nbsp;&nbsp;<a href="tel:+526562157772"> (656) 215 77 72 </a></span>
            </div>
            <div class="rounded border shadow-sm p-4 mb-3">
              <span><i class="bi bi-mailbox"></i>&nbsp;&nbsp;&nbsp;<b>E-mail:</b>&nbsp;&nbsp;&nbsp;<a href="mailto:servicios@gghtrucking.com"> servicios@gghtrucking.com </a></span>
            </div>
            <div class="rounded border shadow-sm p-4 mb-3">
              <!--span><i class="bi bi-map"></i>&nbsp;&nbsp;&nbsp;<b>Direcci&oacute;n:</b>&nbsp;&nbsp;&nbsp; Camino Viejo a San José #6918 Col. Marquis CP. 32536</span-->
              <span><i class="bi bi-map"></i>&nbsp;&nbsp;&nbsp;<b>Direcci&oacute;n:</b>&nbsp;&nbsp;&nbsp; Av.Tecnológico #9471, CP. 32695</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 border">
          <div class="py-1 border-bottom">
            <h5 class="text-center font-weight-bold">Ubicaci&oacute;n</h5>
          </div>
          <!--iframe
              class="w-100 p-2"
              loading="lazy"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3394.34409579445!2d-106.42443088443831!3d31.706494245099286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86e75c3e7cc4fe9d%3A0x50e4a0e41a3c775c!2sCalle%20Camino%20Viejo%20a%20San%20Jos%C3%A9%206918%2C%20Marquis%2C%2032536%20Cd%20Ju%C3%A1rez%2C%20Chih.!5e0!3m2!1ses-419!2smx!4v1621191322884!5m2!1ses-419!2smx"
              style="border:0;
              height: 400px;"
              allowfullscreen="" ></iframe-->
          <iframe
              class="w-100 p-2"
              loading="lazy"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6794.720424356751!2d-106.4455522!3d31.6239864!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86e7618fe0840f8f%3A0xd8da3e32f9145a24!2sPension%20El%20Charro!5e0!3m2!1ses!2smx!4v1674622970403!5m2!1ses!2smx"
              style="border:0;
              height: 400px;"
              allowfullscreen="" ></iframe>
        </div>
      </div>
    </div>

    <footer class="container-fluid p-0">
      <div class="col shadow bg-white border p-2">
        <div class="row no-gutters">
          <div class="col-12 col-lg-4 py-2 py-lg-0  px-3 text-center">
            <p><b>Menu</b></p>
            <ul class="navbar-nav">
              <li class="nav-item active p-0">
                <a class="nav-link active px-3 mb-1 border" href="#inicio"> Inicio </a>
              </li>
              <li class="nav-item">
                <a class="nav-link border mb-1" href="#nosotros"> Nosotros </a>
              </li>
              <li class="nav-item   ">
                <a class="nav-link border mb-1 px-3" href="#servicios"> Servicios </a>
              </li>
              <li class="nav-item  ">
                <a class="nav-link border mb-1 px-3" href="#contacto"> Contacto </a>
              </li>
            </ul>

          </div>
          <div class="col-12 col-lg-4 py-2 py-lg-0 app-center-text-div">
            &copy; 2022 GGH Trucking
          </div>
          <div class="col-12 col-lg-4 py-2 py-lg-0 app-center-text-div">
            <img src="@/assets/logo.webp" alt="Logo GGH Trucking" style="width: 20vw; max-width: 250px; min-height: 30px;">
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {

  },
  setup(){

    return {

    }
  }
}
</script>

<style scoped>
@font-face {
  font-display: swap;
}

.zoom:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.app-center-text-div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-perfect {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.card-slide-width {
  width: 25em;
}

.card-slide-width img {
  width: 20em;
}

.font-size-card-img {
  font-size: 20px;
}


@media screen and (max-width: 992px) {
  .navigator-flex-column {
    flex-direction: column;
    text-align: center;
  }

  .card-slide-width {
    width: 12em;
  }

  .card-slide-width img {
    width: 10em;
  }

  .font-size-card-img {
    font-size: 2vw;
  }
}


</style>























