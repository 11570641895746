<template>
 <div class="col">
   <h1 class="text-center">404 Not Found</h1>
   <p class="text-center"><u>El sitio que estar buscando no fue encontrado</u></p>
   <a class="btn btn-lg btn-primary form-control " href="/">Inicio</a>
 </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>